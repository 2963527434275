import { CliftonUrl, LocalUrl } from "../../../config/env";

// let location = JSON.parse(localStorage.getItem("location"));
// console.log(location, "loc");
export const ProductAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/SimpleProduct/getProduct`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const VariableProductAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/VariableProduct/getProduct`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const CategoryAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/category/get-category`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const ContentAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/content/get-content`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const BeveragesAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/beverages/getBeverage`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const PayemntMethodAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/payment-method/get-payment-method`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

export const ShippingBahadurabadAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(`${LocalUrl}/shipping/get-shipping`, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};

// export const ShippingCliftonAPI = () => {
//   const location = JSON.parse(localStorage.getItem("location"));
//   var requestOptions = {
//     method: "GET",
//     redirect: "follow",
//   };

//   return fetch(`${CliftonUrl}/shipping/get-shipping`, requestOptions)
//     .then((response) => response.json())
//     .catch((error) => {
//       console.log("error", error);
//       throw error; // Rethrow the error so it can be caught by the caller
//     });
// };
export const TaxAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/tax/get-tax`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};
export const SliderAPI = () => {
  const location = JSON.parse(localStorage.getItem("location"));
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(
    `${LocalUrl
    }/slider/get-slider`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      throw error; // Rethrow the error so it can be caught by the caller
    });
};
