import { useNavigate } from "react-router-dom";
import { ErrorImage, Left } from "../../../config/icon";
import "./style.css";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="notfound-container">
      <div className="notfound-wrapper">
        <div className="image-container">
          <img
            alt="404Page"
            src={ErrorImage}
            className="image-notfound"
          />
        </div>
        <h3 className="notfound-text">
          404: The page you are looking for isn’t here
        </h3>
        <button className="go-back-notfound" onClick={() => navigate(-1)}>
          <img src={Left} alt="" width={15} height={15} />
          Go back to HomePage
        </button>
      </div>
    </div>
  );
};

export default NotFound;
